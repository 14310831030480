<template>
  <kdx-modal-frame
    :value="value"
    title="商品分类选择器"
    width="1000"
    :loading="loading"
    ok-text="保存"
    @on-ok="handleSave"
    @on-cancel="handleCancel"
  >
    <div class="goods-category">
      <el-table
        style="width: 100%"
        :data="goodsCategoryList"
        row-key="id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :header-row-style="{ height: '50px', color: '#626e7c' }"
        :header-cell-style="{ background: '#f8f9fb', padding: '8px 0' }"
        :cell-style="changeTr"
        :indent="30"
        default-expand-all
      >
        <el-table-column label="" align="left" min-width="80px">
          <template slot-scope="{ row }">
            <div>
              <el-radio v-model="pageQueryId" :label="row.id">{{
                ""
              }}</el-radio>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="分类ID" align="left" min-width="100px">
          <template slot-scope="{ row }">
            <span>{{ row.id }} </span>
          </template>
        </el-table-column>
        <el-table-column label="商品分类" align="left" min-width="120px">
          <template slot-scope="{ row }">
            {{ row.categoryName }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </kdx-modal-frame>
</template>

<script>
import { getGoodsCategoryTreeAPI } from "@/components/decorate/decorate-components/new-components/select-link/api";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    currentCheckId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      goodsCategoryList: [],
      loading: false,
      pageQueryId: "",
    };
  },

  watch: {
    currentCheckId: {
      handler(newVal) {
        if (newVal) this.pageQueryId = newVal;
      },
    },
    immediate: true,
  },

  computed: {
    selectRows() {
      if (!this.pageQueryId) return [];
      const res = this.goodsCategoryList.find((item) => item.id == this.pageQueryId);
      if (!res) return [];
      return [res];
    },
  },
  methods: {
    async getGoodsCategoryTree() {
      this.loading = true;
      try {
        this.goodsCategoryList = await getGoodsCategoryTreeAPI({ status: 1 });
      } finally {
        this.loading = false;
      }
    },
    handleSave() {
      if (this.multiple) {
        this.$emit("on-change", this.selectRows);
      } else {
        this.$emit("on-change", this.selectRows);
      }
      this.handleCancel();
    },

    handleCancel() {
      this.$emit("on-cancel");
    },

    // 修改第一列无children的样式
    changeTr({ row, column, rowIndex, columnIndex }) {
      if (!row.children && columnIndex == 0) {
        return { paddingLeft: "23px" };
      }
    },
  },
  created() {
    this.getGoodsCategoryTree();
  },
};
</script>

<style lang="scss" scoped>
.goods-category /deep/ {
  padding: 16px;
  width: 100%;
  .el-table {
    .cell {
      display: flex;
      align-items: center;
    }
  }
}
</style>
